import React, { Fragment, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { intlShape } from 'react-intl';
import { InputGroup } from 'cstar-react-primitives/lib/redux-form/InputGroup';

import {
    giftCardFieldNames,
    giftCardFieldAttrs,
    RECIPIENT_EMAIL_FIELD_CONFIRMATION,
    newItemRecipientEmail,
    newItemEmailCheckbox
} from '../item/newItemForm';
import getFieldCounterAriaLabel from '../primitive/getFieldCounterAriaLabel';
import translateErrorOrWarnings from '../validate/translateErrorOrWarning';
import { fieldValidators } from '../item/giftCardValidate';

import deliveryMessages from './deliveryMessages';
import { OPTIONAL } from './PhysicalDeliveryFormConstants';
import EmailDomainSpellCheckAlert from '../primitive/EmailDomainSpellCheckAlert';
import { changeValue } from '../item/newItemFormModule';
import emptyFunc from '../utils/emptyFunc';



const RecipientEmailField = ({
    cashbotName,
    recipientName,
    fieldLabel,
    fieldHelper,
    isPlastic,
    intl,
    doFocus,
    confirmEmailFieldEnabled,
    spellCheckEmailPromptEnabled,
    requireRecipientEmailForPhysicalGift,
    smsDeliveryEnabled,
    optionsRef = { current: { noValidation: false } }
}) => {
    const runValidations = (validateFn, ref) => (value, allValues, props) => {
        if (ref.current && ref.current.noValidation) {
            return emptyFunc();
        }
        return validateFn(value, allValues, props);
    };

    const currentEmailState = useSelector(state => newItemEmailCheckbox(state));
    const emailProps = {
        atts: {
            type: 'email',
            maxLength: giftCardFieldAttrs.EMAIL_FIELD_MAX,
            autoComplete: 'email',
            disabled: smsDeliveryEnabled && !currentEmailState
        },
        label: recipientName
            ? intl.formatMessage(fieldLabel, { name: recipientName })
            : intl.formatMessage(deliveryMessages.emailLabelNoName),
        component: InputGroup,
        name: giftCardFieldNames.RECIPIENT_EMAIL_FIELD,
        cashbotName,
        doFocus,
        hasMaxLengthCounter: true,
        counterAriaLabel: getFieldCounterAriaLabel(intl),
        validate: useCallback(
            runValidations(isPlastic && requireRecipientEmailForPhysicalGift === OPTIONAL
                ? translateErrorOrWarnings(fieldValidators.validatePlasticNotify, intl)
                : translateErrorOrWarnings(fieldValidators.validateRecipientEmail, intl), optionsRef),
            [isPlastic, requireRecipientEmailForPhysicalGift, intl, optionsRef.current.noValidation]
        )
    };

    const confirmEmailProps = { ...emailProps };
    confirmEmailProps.label = recipientName
        ? intl.formatMessage(deliveryMessages.confirmEmailLabel, { name: recipientName })
        : intl.formatMessage(deliveryMessages.confirmEmailLabelNoName);
    confirmEmailProps.cashbotName = RECIPIENT_EMAIL_FIELD_CONFIRMATION;
    confirmEmailProps.name = RECIPIENT_EMAIL_FIELD_CONFIRMATION;
    confirmEmailProps.doFocus = false;
    confirmEmailProps.hasMaxLengthCounter = true;
    confirmEmailProps.counterAriaLabel = getFieldCounterAriaLabel(intl);
    confirmEmailProps.validate = useCallback(
        runValidations(
            translateErrorOrWarnings((value, allValues) => fieldValidators.validateConfirmEmailMatch(
                value, allValues, false
            ), intl), optionsRef
        ),
        [intl, optionsRef.current.noValidation]
    );

    const dispatch = useDispatch();

    const emailDomainSpellCheckProps = {
        message: deliveryMessages.emailDomainSpellCheckText,
        cashbotName: `${cashbotName}-email-spell-check`,
        ariaDescribedby: `${giftCardFieldNames.RECIPIENT_EMAIL_FIELD}-input`,
        email: useSelector(state => newItemRecipientEmail(state)),
        intl,
        handleEmailChange: (suggestedEmail) => {
            dispatch(changeValue(
                giftCardFieldNames.RECIPIENT_EMAIL_FIELD, suggestedEmail
            ));
            document.getElementById('recipientEmailConfirmation-input').focus();
        }
    };
    return (
        <Fragment>
            <div className={`${cashbotName}-${giftCardFieldNames.RECIPIENT_EMAIL_FIELD}`}>
                <Field {...emailProps} />
                {!isPlastic && (
                    <div className='delivery-recipientEmail-helper-text'>
                        {intl.formatMessage(fieldHelper, { name: recipientName })}
                    </div>
                )}
            </div>
            {spellCheckEmailPromptEnabled && (
                <div className='delivery-recipient-email-domain-spell-check-prompt'>
                    <EmailDomainSpellCheckAlert {...emailDomainSpellCheckProps} />
                </div>
            )}
            {confirmEmailFieldEnabled && (
                <div className='delivery-recipient-email-confirmation'>
                    <Field {...confirmEmailProps} />
                </div>
            )}
        </Fragment>
    );
};

RecipientEmailField.defaultProps = {
    fieldLabel: deliveryMessages.emailLabel,
    fieldHelper: deliveryMessages.emailHelper,
    requireRecipientEmailForPhysicalGift: OPTIONAL,
    recipientName: '',
    doFocus: false,
    isPlastic: false,
    confirmEmailFieldEnabled: false,
    spellCheckEmailPromptEnabled: false,
    disabled: false,
    smsDeliveryEnabled: false,
    optionsRef: { current: { noValidation: false } }
};

RecipientEmailField.propTypes = {
    cashbotName: PropTypes.string.isRequired,
    fieldLabel: PropTypes.object,
    fieldHelper: PropTypes.object,
    requireRecipientEmailForPhysicalGift: PropTypes.string,
    recipientName: PropTypes.string,
    isPlastic: PropTypes.bool,
    intl: intlShape.isRequired,
    doFocus: PropTypes.bool,
    confirmEmailFieldEnabled: PropTypes.bool,
    spellCheckEmailPromptEnabled: PropTypes.bool,
    disabled: PropTypes.bool,
    smsDeliveryEnabled: PropTypes.bool,
    optionsRef: PropTypes.object
};

export default RecipientEmailField;
