import React, {
    Fragment, useEffect, useState, useRef
} from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { Field } from 'redux-form/immutable';
import { TextAreaGroup } from 'cstar-react-primitives/lib/redux-form/TextAreaGroup';
import form from 'redux-form/lib/Form';
import { Container } from 'cstar-react-primitives/lib/redux-form/layout/Container';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import { NameFields } from '../recipient/RecipientNameForm';
import {
    EMAIL_FIELD_SELECTED,
    IS_SHARE_VIA_LINK,
    MESSAGE_FIELD, newItemIsSelfBuy
} from '../item/newItemForm';
import messageIntl from '../message/messageIntl';
import getFieldCounterAriaLabel from '../primitive/getFieldCounterAriaLabel';
import { normalizeLineBreaks } from '../message/MessageForm';
import {
    getIsShareViaLinkDefaultDeliveryOption, getIsShareViaLinkDeliveryOptionEnabled, getSMSEnabled,
    maxMessageCharLength
} from '../brand/brandSelectors';
import { getSelectedFaceplateCategory } from '../faceplate/faceplateSelectors';
import { getInspirationsForGroup } from '../faceplatePresentation/faceplatePresentationSelectors';
import RecipientEmailField from '../delivery/RecipientEmailField';
import { SelfBuyFields } from '../recipient/SelfBuyFields';
import { DeliveryMethodRadio } from '../delivery/ElectronicDeliveryForm';
import { setDefaultFieldValues } from '../item/newItemFormModule';
import recipientMessages from '../recipient/recipientMessages';
import deliveryMessages from '../delivery/deliveryMessages';

const RecipientDetails = ({
    intl, maxLength, isPlastic, recipientName, isSMSDeliveryForRecipientEnabled
}) => {
    const dispatch = useDispatch();
    const isShareViaLinkDeliveryOptionEnabled = useSelector(state => getIsShareViaLinkDeliveryOptionEnabled(state));
    const isShareViaLinkDefaultDeliveryOption = useSelector(state => getIsShareViaLinkDefaultDeliveryOption(state));

    let defaultRadioOption;
    if (isShareViaLinkDeliveryOptionEnabled) {
        if (isShareViaLinkDefaultDeliveryOption) {
            defaultRadioOption = true;
        } else {
            defaultRadioOption = false;
        }
    }
    const [isSharableLinkSelected, setIsSharableLinkSelected] = useState(defaultRadioOption);
    const isSelfBuy = useSelector(state => newItemIsSelfBuy(state));

    useEffect(() => {
        if (isSelfBuy === true || isPlastic) {
            const updatedValues = fromJS({
                [IS_SHARE_VIA_LINK]: false,
                [EMAIL_FIELD_SELECTED]: false
            });
            dispatch(setDefaultFieldValues(updatedValues, true));
        } else if (!isPlastic) {
            const updatedValues = fromJS({
                [IS_SHARE_VIA_LINK]: isSharableLinkSelected,
                [EMAIL_FIELD_SELECTED]: !isSharableLinkSelected
            });
            dispatch(setDefaultFieldValues(updatedValues, true));
        }
    }, [isSelfBuy]);

    useEffect(() => {
        if (!isPlastic) {
            const updatedValues = fromJS({
                [IS_SHARE_VIA_LINK]: isSharableLinkSelected,
                [EMAIL_FIELD_SELECTED]: !isSharableLinkSelected
            });
            dispatch(setDefaultFieldValues(updatedValues, true));
        }
    }, [isPlastic]);

    const recipientTitle = recipientMessages.title;
    const validationRef = useRef({ noValidation: false });
    validationRef.current.noValidation = (isSelfBuy || isSharableLinkSelected || isPlastic);

    const messageFormProps = {
        atts: {
            maxLength
        },
        isPlastic,
        component: TextAreaGroup,
        name: MESSAGE_FIELD,
        label: intl.formatMessage(messageIntl.label),
        formName: form,
        cashbotName: 'message',
        doFocus: false,
        format: normalizeLineBreaks(isPlastic),
        normalize: normalizeLineBreaks(isPlastic),
        counterAriaLabel: getFieldCounterAriaLabel(intl)
    };

    const emailProps = {
        intl,
        recipientName: 'Recipient',
        isPlastic: false,
        cashbotName: 'delivery',
        doFocus: false,
        confirmEmailFieldEnabled: !isSMSDeliveryForRecipientEnabled,
        spellCheckEmailPromptEnabled: true,
        smsDeliveryEnabled: isSMSDeliveryForRecipientEnabled,
        optionsRef: validationRef
    };

    const containerProps = {
        heading: intl.formatMessage(recipientTitle),
        HeadingElement: 'h1'
    };

    const setRadioSelection = (value) => {
        setIsSharableLinkSelected(value);
    };

    useEffect(() => {
        const updatedValues = fromJS({
            [IS_SHARE_VIA_LINK]: isSharableLinkSelected,
            [EMAIL_FIELD_SELECTED]: !isSharableLinkSelected
        });
        dispatch(setDefaultFieldValues(updatedValues, true));
    }, [isSharableLinkSelected]);

    useEffect(() => {
        if (isSharableLinkSelected) {
            const updatedValues = fromJS({
                [IS_SHARE_VIA_LINK]: true,
                [EMAIL_FIELD_SELECTED]: false
            });
            dispatch(setDefaultFieldValues(updatedValues, true));
        }
    }, []);


    return (

        <div className="container">
            <Container {...containerProps} />
            <div className="recipient">
                <SelfBuyFields cashbotName='recipient' intl={intl} isPDPFlow />
            </div>
            {!isPlastic ? (
                <div className="digital-form-wrapper">
                    {!isSelfBuy ? (
                        <div className="for-friend-form-wrapper">
                            {isShareViaLinkDeliveryOptionEnabled
                                && <DeliveryMethodRadio intl={intl} setRadioSelection={setRadioSelection} />}
                            <div className='fields-div'>
                                {!isSharableLinkSelected ? (
                                    <React.Fragment>
                                    </React.Fragment>
                                ) : (
                                    <div className='link-text'>
                                        {intl.formatMessage(deliveryMessages.sharableLinkMessage,
                                            { name: recipientName })}
                                    </div>
                                )}
                                <NameFields cashbotName="recipient" intl={intl} />
                                <Fragment>
                                    <div className="message-field-container">
                                        <Field {...messageFormProps} />
                                    </div>
                                </Fragment>
                                {!isSharableLinkSelected ? (
                                    <div className='field-group__div'>
                                        {<RecipientEmailField {...emailProps} />}
                                    </div>
                                ) : (
                                    <React.Fragment>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : (
                <div className="plastic-form-wrapper">
                    {!isSelfBuy ? (
                        <Fragment>
                            <div className='fields-div'>
                                <div className="physical-form">
                                    <NameFields cashbotName="recipient" intl={intl} />
                                    <div className="message-field-container">
                                        <Field {...messageFormProps} />
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    ) : null}
                </div>
            )}
        </div>
    );
};

RecipientDetails.defaultProps = {
    hasPlasticNotification: false,
    maxLength: 0,
    isPlastic: false,
    messageInspiration: {},
    recipientName: '',
    isSMSDeliveryForRecipientEnabled: false,
    requireRecipientEmailForPhysicalGift: ''
};

RecipientDetails.propTypes = {
    intl: intlShape.isRequired,
    maxLength: PropTypes.number,
    isPlastic: PropTypes.bool,
    messageInspiration: PropTypes.shape({
        size: PropTypes.number
    }),
    recipientName: PropTypes.string,
    isSMSDeliveryForRecipientEnabled: PropTypes.bool,
    requireRecipientEmailForPhysicalGift: PropTypes.string,
    hasPlasticNotification: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
    const { isPlastic } = ownProps;
    const maxLength = maxMessageCharLength(state, { isPlastic });
    const category = getSelectedFaceplateCategory(state);
    const messageInspiration = getInspirationsForGroup(state, isPlastic, category ? category.get('name') : undefined);
    const isRecipientSMSEnabled = getSMSEnabled(state);
    return {
        maxLength,
        messageInspiration,
        isRecipientSMSEnabled
    };
};

export default connect(mapStateToProps)(injectIntl(RecipientDetails));
